import React from "react";
import { graphql } from "gatsby";
import Layout from "../containers/layout";
import GraphQLErrorList from "../components/graphql-error-list";
import Seo from "../components/seo";
import { toPlainText } from "../lib/helpers";

import PageHeader from "../components/partner-page-header";
import PartnerMain from "../components/partner-main";
import ShowcaseSection from "../components/showcase-section";
import LatestPosts from "../components/posts-latest";

export const query = graphql`
  fragment SanityImage on SanityMainImage {
    crop {
      _key
      _type
      top
      bottom
      left
      right
    }
    hotspot {
      _key
      _type
      x
      y
      height
      width
    }
    asset {
      _id
    }
  }

  query PartnerTemplateQuery($id: String!) {
    partner: sanityPartner(id: { eq: $id }) {
      id
      name
      logo {
        asset {
          _id
        }
        alt
      }
      customPageTitle
      _rawDescription
      button {
        text
        url
        secondaryButton
        blank
        document {
          asset {
            url
            originalFilename
          }
        }
      }
      thumbnail {
        ...SanityImage
        alt
      }
      flexibleContent {
        ... on SanityFc2ColumnImage {
          _key
          _type
          imageLeft {
            ...SanityImage
            alt
            caption
          }
          imageRight {
            ...SanityImage
            alt
            caption
          }
        }
        ... on SanityFcContentBlock {
          _key
          _type
          _rawContent
        }
        ... on SanityFcImage {
          _key
          _type
          fullwidth
          image {
            ...SanityImage
            alt
            caption
          }
        }
        ... on SanityFcLogos {
          _key
          _type
          title
          logos {
            _key
            logo {
              asset {
                _id
              }
              alt
            }
            url
            blank
          }
        }
        ... on SanityFcVideo {
          _key
          _type
          caption
          url
        }
      }
      showcaseSection {
        title
        showcase {
          _key
          showcase {
            title
            slug {
              current
            }
            thumbnail {
              ...SanityImage
              alt
            }
            categories {
              _key
              showcaseCategory {
                title
                slug {
                  current
                }
              }
            }
          }
        }
      }
      latestPosts {
        ... on SanityLibraryReference {
          _key
          _type
          library {
            title
            slug {
              current
            }
            thumbnail {
              ...SanityImage
              alt
            }
            types {
              _key
              libraryType {
                title
                slug {
                  current
                }
              }
            }
          }
        }
        ... on SanityNewsReference {
          _key
          _type
          news {
            title
            slug {
              current
            }
            publicationDate
            thumbnail {
              ...SanityImage
              alt
            }
          }
        }
      }
      seo {
        title
        description
        thumbnail {
          asset {
            _id
          }
          alt
        }
      }
    }

    news: allSanityNews(
      sort: { fields: [publicationDate], order: DESC }
      limit: 3
    ) {
      edges {
        node {
          id
          title
          slug {
            current
          }
          publicationDate
          thumbnail {
            ...SanityImage
            alt
          }
        }
      }
    }
  }
`;

const PartnerTemplate = (props) => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }
  
  const partner = (data || {}).partner;
  const news = (data || {}).news.edges;

  const title = partner.seo?.title || partner.name;
  const description = partner.seo?.description || toPlainText(partner._rawDescription);
  const image = partner.seo?.thumbnail || partner.thumbnail;
  const autoSiteTitle = !partner.seo?.title;

  return (
    <Layout>
      <Seo
        title={title}
        description={description}
        image={image}
        autoSiteTitle={autoSiteTitle}
      />

      {(partner.name || partner.customPageTitle) && (<PageHeader title={partner.customPageTitle} name={partner.name} description={partner._rawDescription} button={partner.button} logo={partner.logo} image={partner.thumbnail} />)}
      {partner.flexibleContent && (<PartnerMain content={partner.flexibleContent} />)}
      {partner.showcaseSection && (<ShowcaseSection {...partner.showcaseSection} />)}
      {(news || partner.latestPosts) && (<LatestPosts dPosts={news} mPosts={partner.latestPosts} />)}
    </Layout>
  );
};

export default PartnerTemplate;
